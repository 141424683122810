import React, {Component} from 'react';

import ReactMetaTags from 'react-meta-tags';

import sharingImage from "../../Assets/sharing.jpg";

class MetaTags extends Component {

    render() {

        const defaultTitle = "Was wird es denn? Kostenlose Online Rubbel Karte zum teilen und freuen";
        const title = this.props.title || defaultTitle;

        const defaultDescription = "Jetzt kannst du deiner Familie, Freunden und Bekannten kontaktlos auf spielerische Art und Weise diese Frage mit einer Online Rubbelkarte beantworten.";
        const description = this.props.description || defaultDescription;

        return (
            <ReactMetaTags>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta property="og:title" content={title} />
                <meta property="og:image" content={sharingImage} />
            </ReactMetaTags>
        )
    }
}

export default MetaTags;
