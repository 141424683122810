import React, {Component} from 'react';
import Landing from "../../Modules/Landing/Landing";
import Footer from "../../Modules/Footer/Footer";
import MetaTags from "../../Modules/MetaTags/MetaTags";

class Homepage extends Component {


    render() {

        return (
            <>
                <MetaTags
                    title={"Was wird es denn? Kostenlose Online Rubbel Karte zum teilen und freuen"}
                    description={"Jetzt kannst du deiner Familie, Freunden und Bekannten kontaktlos auf spielerische Art und Weise diese Frage mit einer Online Rubbelkarte beantworten."}
                />
                <Landing />
                <Footer />
            </>
        );
    }
}

export default Homepage;
