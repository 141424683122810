import React, {Component} from 'react';
import ScratchField from "../../Elements/ScratchField/ScratchField";
import Icon from "../../Elements/Icon/Icon";

import bg1 from './bg1.svg';
import './ScratchCard.scss';

var classNames = require('classnames');

const giftLink = 'https://www.babyone.de/geschenke-geburt-taufe?utm_source=was-wird-es-denn.de';

class ScratchCard extends Component {

    constructor(props) {
        super(props);
        this.scratchCardContainer = React.createRef();

        this.state = {
            realViewPortHeight: null,
            scratchCardContainerWidth: 200,
            handleBackgroundImageLoaded: false,
            scratchFieldLoaded: false,
            gender: this.props.gender,
            showAffiliateLink: false,
            cachedWindowHeight: 0,
            cachedWindowWidth: 0,
        };
    }

    componentDidMount() {
        if (typeof window !== "undefined") {
            window.addEventListener('resize', this.handleResize.bind(this));

            this.setState({
                cachedWindowHeight: window.outerHeight,
                cachedWindowWidth: window.outerWidth,
                realViewPortHeight: window.innerHeight,
            });
        }
    }

    handleResize() {

        if (this.state.cachedWindowHeight !== window.outerHeight || this.state.cachedWindowWidth !== window.outerWidth) {
            this.setState({
                scratchCardContainerWidth: this.scratchCardContainer.current.clientWidth,
                realViewPortHeight: window.innerHeight,
                cachedWindowHeight: window.outerHeight,
                cachedWindowWidth: window.outerWidth,
            });
        }
    }

    handleBackgroundImageLoaded() {
        this.setState({
            backgroundImageLoaded: true,
            scratchCardContainerWidth: this.scratchCardContainer.current.clientWidth,
        });
    }

    handleScratchFieldLoaded() {
        this.setState({
            scratchFieldLoaded: true,
        });
    }

    showLink() {
        this.setState({
            showAffiliateLink: true,
        });    }

    render() {

        let classNamesHeartIcon = classNames(
            'ScratchCard-ScratchFieldContainer-Heart-Icon',
            {
                'ScratchCard-ScratchFieldContainer-Heart-Icon-Boy': this.state.gender === 'boy',
                'ScratchCard-ScratchFieldContainer-Heart-Icon-Girl': this.state.gender === 'girl'
            }
        );

        return (
            <div className="ScratchCard" key={this.state.gender} style={{height: this.state.realViewPortHeight}}>
                <div className="ScratchCard-Background">
                    <img
                        className="ScratchCard-Background-Image"
                        src={bg1}
                        alt={"Scratch card background"}
                        onLoad={this.handleBackgroundImageLoaded.bind(this)}
                        style={{maxHeight: this.state.realViewPortHeight}}
                    />
                    {this.state.showAffiliateLink ? (
                        <a href={giftLink} target="_blank" rel="noreferrer" className="ScratchCard-ScratchFieldContainer-LinkGift" >
                            { this.state.gender === 'boy' ? (
                                <>
                                    Hurra! Wir bekommen einen <span className="color-boy"><strong>Jungen</strong></span>!<br />
                                </>
                            ) :
                                <>
                                    Hurra! Wir bekommen ein <span className="color-girl"><strong>Mädchen</strong></span>!<br />
                                </>
                            }
                            Klicke <strong>hier</strong> und finde ein passendes Geschenk!
                        </a>
                    ) : null }
                    <div className="ScratchCard-ScratchFieldContainer" ref={this.scratchCardContainer}>
                        {this.state.scratchFieldLoaded ? (
                            <div className="ScratchCard-ScratchFieldContainer-Heart">
                                <Icon icon={"heart"} className={classNamesHeartIcon} size={"60%"}/>
                            </div>
                        ) : null }
                        {this.state.backgroundImageLoaded ? (
                            <ScratchField
                                size={this.state.scratchCardContainerWidth}
                                runOnPercentage={[{ percentage: 80, func: this.showLink.bind(this) }]}
                                runOnLoaded={this.handleScratchFieldLoaded.bind(this)}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default ScratchCard;
