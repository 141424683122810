import React, {Component} from 'react';

import "./Landing.scss";
import {Button, Container, Image, Modal, Row} from "react-bootstrap";

import ImageHeaderBackground from "./smartphone.svg";
import GenerateLinkForm from "../../Modules/GenerateLinkForm/GenerateLinkForm";

class Landing extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showCreateLinkModal: window.location.hash === '#link-erstellen',
        };
    }

    handleShowCreateLink() {
        this.setState(
            {showCreateLinkModal: true}
        )
    }

    handleCloseCreateLink() {
        this.setState(
            {showCreateLinkModal: false}
        )
    }

    render() {

        return (
            <div className={"background-heart"}>
                <Container>
                    <Row className={"min-vh-100"}>
                        <div className="col-lg-7 px-5 mt-5 my-lg-auto text-center text-lg-left">
                            <h1 className="mb-3 display-4"><strong>Was wird es denn?</strong></h1>
                            <p>
                                <strong>Jetzt kannst du deiner Familie, Freunden und Bekannten kontaktlos auf spielerische Art und Weise diese Frage mit einer Online Rubbelkarte beantworten.</strong>
                            </p>
                            <p className="mb-4">
                                <strong>Generiere jetzt kostenlos deinen persönlichen Link und teile ihn mit allen, die sich mit dir freuen!</strong>
                            </p>
                            <Button size={"lg"} variant={"outline-primary"} onClick={() => this.handleShowCreateLink()}><strong>JETZT KOSTENLOS LINK GENERIEREN</strong></Button>
                        </div>
                        <div className="col-lg-5 px-5 mt-5 py-5 my-lg-auto text-center">
                            <Image src={ImageHeaderBackground} fluid alt={"Screenshot of scratch page"} />
                        </div>
                    </Row>
                </Container>
                <Modal centered show={this.state.showCreateLinkModal} onHide={() => this.handleCloseCreateLink()}>
                    <Modal.Body>
                        <GenerateLinkForm />
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default Landing;
