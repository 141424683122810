import React, {Component} from 'react';
import {Button, Col, Container, Image, Row} from "react-bootstrap";
import NotFoundImage from "./404-not-found-sleeping-rabbit.svg";

import "./../../_Scss/Default.scss";
import {Link} from "react-router-dom";

class NotFound extends Component {

    render() {

        return (
            <div className={"background-heart"}>
                <Container>
                    <Row className={"min-vh-100"}>
                        <Col lg={{ span: 6, offset: 1 }} className={"px-5 mt-5 my-lg-auto text-center text-lg-left"} >
                            <h1 className="mb-3 display-4"><strong>404 - Seite nicht gefunden</strong></h1>
                            <p>
                                <strong>Die angegebene Seite konnte nicht gefunden werden.</strong>
                            </p>
                            <Link
                                to="/"
                                component={Button}
                                size={"lg"}
                                variant={"outline-primary"}
                                title={"Zur Startseite zurück"}
                            >
                                Zur Startseite
                            </Link>
                        </Col>
                        <Col lg={{ span: 4 }} className="px-5 mt-5 py-5 my-lg-auto text-center">
                            <Image src={NotFoundImage} fluid />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default NotFound;
