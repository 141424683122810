import React, {Component} from 'react';
import Footer from "../../Modules/Footer/Footer";
import MetaTags from "../../Modules/MetaTags/MetaTags";
import NotFoundModule from "../../Modules/NotFound/NotFound";

class NotFound extends Component {

    render() {
        return (
            <>
                <MetaTags
                    title={"404 - Seite nicht gefunden"}
                    description={"Die angegebene Seite konnte nicht gefunden werden."}
                />
                <NotFoundModule />
                <Footer />
            </>
        );
    }
}

export default NotFound;
