import React, {Component} from 'react';

import '../../_Scss/Default.scss';
import {Container} from "react-bootstrap";

var classNames = require('classnames');

class CallToAction extends Component {

    render() {

        let classNamesContainer = classNames(
            'text-center py-5',
            {
                'background-heart': this.props.background,
            }
        );
        return (
            <div className={classNamesContainer}>
                <Container >
                    <h3>
                        {this.props.headline}
                    </h3>
                    <p className={"lead"}>
                        {this.props.description}
                    </p>
                    {this.props.children}
                </Container>
            </div>
        );
    }
}

export default CallToAction;
