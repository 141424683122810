// available chars for calculation
const availableChars = 'abcdefghijklmnopqrstuvwxyz01234567890';

/**
 * Generates an int representation of a string
 *
 * @param string
 * @returns {number}
 */
function getIntRepresentation(string) {
    let sumIntValue = 0;

    for (let i = 0; i < string.length; i++) {
        sumIntValue += availableChars.indexOf(string.charAt(i));
    }

    return sumIntValue
}

/**
 * Generates a random string with the length of length
 * @param length
 * @returns {string}
 */
function getRandomString(length) {
    const chars = "abcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
}

/**
 * Creates an encrypted link.
 * Each random generated char of the string randomString
 * will be converted to an int. The mod 10 of the sum
 * must be 1 for female and 2 for male so an additional
 * char will be added which
 *
 * @param gender
 * @returns {string}
 * @constructor
 */
export function EncryptLink(gender) {

    let randomString = getRandomString(6);

    let sumIntValue = getIntRepresentation(randomString);

    let targetMod = gender === 'girl' ? 11 : 12;

    return randomString + availableChars.charAt(targetMod-(sumIntValue % 10));
}

/**
 *
 * @param string
 * @returns {string|null}
 * @constructor
 */
export function DecryptLink(string) {

    let mod = getIntRepresentation(string) % 10;

    if (mod === 1) {
        return 'girl';
    } else if (mod === 2) {
        return 'boy'
    }

    return null;
}
