import React, {Component} from 'react';
import ScratchCard from "../../Modules/ScratchCard/ScratchCard";
import {DecryptLink} from "../../Tools/Link";
import NotFound from "../NotFound/NotFound";
import Footer from "../../Modules/Footer/Footer";
import CallToAction from "../../Elements/CallToAction/CallToAction";
import MetaTags from "../../Modules/MetaTags/MetaTags";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";

class ScratchPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.id,
            gender: DecryptLink(this.props.match.params.id),
        };
    }

    render() {
        if (this.state.gender) {
            return (
                <>
                    <MetaTags
                        title={"Was wird es denn? Jetzt rubbeln und heraus finden!"}
                        description={"Wir bekommen ein Baby! Rosa oder blau? Beantworte jetzt diese Frage mit einer Online Rubbel Karte."}
                    />
                    <ScratchCard gender={this.state.gender}/>
                    <CallToAction
                        headline={"Du bekommst ein Baby?"}
                        description={"Erstelle deinen eigenen Link und sende ihn an alle die sich mit dir freuen."}
                        background={true}
                    >
                        <Link
                            to="/#link-erstellen"
                            component={Button}
                            type="submit"
                            size={"lg"}
                            variant={"outline-primary"}
                            title={"Erstelle deinen eigenen Link"}
                        >
                            Jetzt Link erstellen
                        </Link>
                    </CallToAction>
                    <Footer />
                </>
            );
        } else {
            return (
                <NotFound/>
            );
        }

    }
}

export default ScratchPage;
