import React, {Component} from 'react';

import './Footer.scss';
import {Container} from "react-bootstrap";
import Icon from "../../Elements/Icon/Icon";

class Footer extends Component {

    render() {

        return (
           <footer>
               <Container className="text-center my-5">
                   <p className="mb-0">Wir freuen uns von dir zu hören:</p>
                   <p><a href="mailto:hallo@was-wird-es-denn.de">hallo@was-wird-es-denn.de</a></p>
                   <p>Made with <Icon icon={"heart"} className={"color-girl"} size={20}/>️ in Neckarsulm</p>
               </Container>
           </footer>
        );
    }
}

export default Footer;
