import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
} from "react-router-dom";

import Homepage from "./Pages/Homepage/Homepage";
import ScratchPage from "./Pages/ScratchPage/ScratchPage";
import NotFound from "./Pages/NotFound/NotFound";
import Imprint from "./Pages/Imprint/Imprint";
import {useEffect} from "react";

let _paq = window._paq = window._paq || [];
(function() {
    let u="https://m.michilehr.de/";
    _paq.push(['setTrackerUrl', u+'m.php']);
    _paq.push(['setSiteId', '3']);
    let d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.type='text/javascript'; g.async=true; g.src=u+'m.js'; s.parentNode.insertBefore(g,s);
})();

export const RouterConfig = () => {

    const location = useLocation();

    useEffect(() => {

        let _paq = window._paq || [];

        _paq.push(['trackPageView']);
    }, [location]);

    return (
        <Switch>
            <Route exact path="/">
                <Homepage />
            </Route>
            <Route exact path="/s/:id" component={ScratchPage} />
            <Route exact path="/impressum" component={Imprint} />
            <Route path="*" component={NotFound}/>
        </Switch>
  );
}

function App () {
    return (
        <Router>
            <RouterConfig />
        </Router>
    )
}

export default App;
