import React from "react";

import {EncryptLink} from "../../Tools/Link";
import {Button, Col, Container, Row} from "react-bootstrap";
import Icon from "../../Elements/Icon/Icon";

import './GenerateLinkForm.scss';

const status = {
    open: 0,
    loading: 1,
    created: 2,
}

class GenerateLinkForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gender: null,
            status: status.open,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({
            gender: event.target.value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            status: status.loading
        }, () => {
            setTimeout(() => {
                this.setState({
                    status: status.created
                })
            }, 600)
        });
    }

    render() {
        if (this.state.status === status.open) {
            return (
                <Container className={"py-3"}>
                    <form className={"GenerateLinkForm-Form"} onSubmit={this.handleSubmit}>
                        <Row className={"mb-5"}>
                            <Col className={"text-center"}>
                                <h2><span className={"color-girl"}>ROSA</span> oder <span className={"color-boy"}>BLAU</span>?</h2>
                            </Col>
                        </Row>
                        <Row className={"mb-4"}>
                            <Col xs={{ span: 6, offset: 0 }} md={{ span: 5, offset: 1 }} className={"text-center"}>
                                <input className={"GenerateLinkForm-Form-InputRadio"} type="radio" value="girl" id="girl" name="gender" onChange={this.handleChange} />
                                <label className={"GenerateLinkForm-Form-Label"} htmlFor="girl">
                                    <Icon icon={"heart"} className={"color-girl w-50"} size={"50%"}/>
                                </label>
                            </Col>
                            <Col xs={{ span: 6 }} md={{ span: 5 }} className={"text-center"}>
                                <input className={"GenerateLinkForm-Form-InputRadio"} type="radio" value="boy" id="boy" name="gender" onChange={this.handleChange} />
                                <label className={"GenerateLinkForm-Form-Label"} htmlFor="boy">
                                    <Icon icon={"heart"} className={"color-boy"} size={"50%"}/>
                                </label>
                            </Col>
                        </Row>
                        <Row className={`${this.state.gender ? "" : "invisible"}`}>
                            <Col className={"text-center"}>
                                <Button type="submit" size={"lg"} variant={"outline-primary"}>Link generieren</Button>
                            </Col>
                        </Row>
                    </form>
                </Container>
            )
        } else if (this.state.status === status.loading) {
            return (
                <div className={"text-center"}>
                    Dein Link wird generiert...
                </div>
                )
        } else if (this.state.status === status.created) {
            return (
                <>
                    <div className={"text-center"}>
                        <p>Dein Link wurde erfolgreich generiert</p>
                        <Button
                            href={`/s/${EncryptLink(this.state.gender)}`}
                            target={"_blank"}
                            variant={"outline-primary"}
                            size={"lg"}
                        >Klicke hier</Button>
                    </div>
                </>
            )
        }
    }
}

export default GenerateLinkForm;
